import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'completeProject'
})
export class CompleteProjectPipe implements PipeTransform {

  sortFn(n1, n2) {
    let name_a = n1.customer.name;
    let name_b = n2.customer.name;
    if (name_a < name_b) return -1;
    if (name_a > name_b) return 1;
    return 0;
  }

  transform(value: Array<any>, showIncomplete: boolean, showInactiveCustomer: boolean): unknown {
    if(!showInactiveCustomer){
      value = value?.filter( x => x.customer?.visibleForBilling)
    } 
    //value have projects active
    if (!showIncomplete) {
      //show all (project active)
      return value?.sort(this.sortFn);
    }
    //filter active but incomplete: if project have not billingGroup and hourlyRate = 0 | null
    return value?.filter(x => x.isActive && (x.billingGroup == null || (x.hourlyRate == 0 || x.hourlyRate == null))).sort(this.sortFn)
  }

}
